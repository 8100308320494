import React, { useEffect, useState } from "react";
import Template from "../../shared/Template";
import moment from "moment";
import currencyUtil from "../../../utils/currencyUtil";
import { Badge, AdminNav, Button, Modal } from "../../../components";
import { CheckedBox } from "../../../assets/icon";
import Wheel from "../../../assets/img/bengkel.png";

import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getDetailTransaction,
  processTransaction,
  processDealerTransaction,
} from "../../TransactionManagementV2/transaction.api";

import "./styles.scss";

const defineStatus = (dataStatus) => {
  const result =
    dataStatus === "Dibatalkan"
      ? " - "
      : dataStatus === "Selesai"
      ? " - "
      : dataStatus === "Dikirim"
      ? "Siap Dipasang"
      : dataStatus === "Siap Dipasang"
      ? "Proses Pasang"
      : dataStatus === "Proses Pemasangan"
      ? "Pemasangan Selesai"
      : "";
  return result;
};

const TransactionDetail = () => {
  const { detailTransaction, loading } = useSelector(
    (state) => state.transaction
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [popupAddress, setPopupAddress] = useState(false);
  const [isConfirmModalActive, setIsConfirmModalActive] = useState(false);
  useEffect(() => {
    dispatch(getDetailTransaction({ id }));
  }, [dispatch]);

  moment.locale();

  const handleTransaction = () => {
    let data = {
      id: { id },
    };

    if (detailTransaction?.status === "Dikirim") {
      data = {
        ...data,
        statusNumber: "7",
      };
    } else if (detailTransaction?.status === "Siap Dipasang") {
      data = {
        ...data,
        statusNumber: "8",
      };
    } else if (detailTransaction?.status === "Proses Pemasangan") {
      data = {
        ...data,
        statusNumber: "9",
      };
    } else if (detailTransaction?.status === "Refund") {
      data = {
        ...data,
        statusNumber: "10",
      };
    } else if (detailTransaction?.status === "Refund Diproses") {
      data = {
        ...data,
        statusNumber: "11",
      };
    } else if (detailTransaction?.status === "Refund Selesai") {
      data = {
        ...data,
        statusNumber: "12",
      };
    }

    dispatch(processDealerTransaction(data))
      .unwrap()
      .then(() => {
        setIsConfirmModalActive(false);
        history.go(0);
      })
      .catch((err) => {
        history.go(0);
      });
    // alert("Aksi Tidak Valid");
    return;
  };

  let component = () => {
    return (
      <>
        <AdminNav pageTitle={"Detail Pesanan"} onChange={() => {}} />
        <div className="transaction-management-detail__header">
          {loading ? (
            <div>Loading..</div>
          ) : (
            <>
              <div className="transaction-list-detail">
                <div className="transaction-header">
                  <div>
                    <div
                      className="back-nav"
                      onClick={() => {
                        history.push("/transaction-management");
                      }}
                    >
                      {"<Kembali"}
                    </div>
                    <div>
                      <Badge
                        variant={
                          detailTransaction?.status === "Dibatalkan"
                            ? "contrast-red"
                            : detailTransaction?.status === "Selesai"
                            ? "green"
                            : detailTransaction?.status === "Diproses"
                            ? "yellow"
                            : detailTransaction?.status === "Dikirim"
                            ? "red"
                            : detailTransaction?.status === "Dipasang"
                            ? "blue"
                            : detailTransaction?.status === "Siap Dipasang"
                            ? "blue"
                            : detailTransaction?.status === "Proses Pemasangan"
                            ? "blue"
                            : detailTransaction?.status === "Pemasangan Selesai"
                            ? "green"
                            : detailTransaction?.status === "Selesai"
                            ? "green"
                            : detailTransaction?.status === "Belum Bayar"
                            ? "grey-dark"
                            : "grey"
                        }
                      >
                        {detailTransaction?.status}
                      </Badge>
                    </div>
                    <div>
                      <p>Pelanggan:</p>
                      <p
                        style={{
                          fontWeight: "bold",
                          position: "relative",
                          textDecoration: "underline",
                        }}
                        onClick={() => setPopupAddress(!popupAddress)}
                      >
                        {detailTransaction?.order_address?.receiver}
                        {popupAddress ? (
                          <div className="popup-address">
                            <p>{`${detailTransaction?.order_address?.address}`}</p>
                            <p>{`${detailTransaction?.order_address?.province_name}, ${detailTransaction?.order_address?.city_name}, ${detailTransaction?.order_address?.district_name}, ${detailTransaction?.order_address?.village_name}`}</p>
                            <p>{`${detailTransaction?.order_address?.postcode}`}</p>{" "}
                          </div>
                        ) : null}
                      </p>
                    </div>
                    <div>
                      <p>Nomor Pesanan:</p>
                      <p style={{ fontWeight: "bold" }}>
                        {detailTransaction?.order_number}
                      </p>
                    </div>
                    <div>
                      <p>Waktu Pesanan Dibuat:</p>
                      <p style={{ fontWeight: "bold" }}>
                        {moment(detailTransaction?.created_at).format(
                          "dddd, Do MMMM YYYY"
                        )}
                      </p>
                    </div>
                    <div>
                      <p>Methode Pengiriman:</p>
                      <p style={{ fontWeight: "bold" }}>
                        {detailTransaction?.order_shipment?.courier_name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="transaction-body">
                  <div className="first">
                    {detailTransaction?.order_products?.map(
                      (transaction, index) => {
                        return (
                          <div className="product-container" key={index}>
                            <img src={transaction.product_image} alt="" />
                            <div className="brand">
                              <div>{transaction.brand_name}</div>
                              <div>{transaction.product_name}</div>
                              {/* <div>+7 Product Lainnya</div> */}
                            </div>
                            <div className="qty">
                              <div>Jumlah</div>
                              <div>{`x ${transaction.quantity}`}</div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="grey">
                    <div className="addonsTitle">Add-On Service</div>
                    <div className="addons">
                      {detailTransaction?.order_addons?.map((addon, index) => {
                        return (
                          <div key={index}>
                            <div>
                              <CheckedBox /> {addon.name}
                            </div>
                            <div>{`Rp. ${currencyUtil.formatRupiah(
                              addon.price
                            )}`}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="transaction-footer">
                  {detailTransaction?.status ===
                  "Belum Bayar" ? null : detailTransaction?.status ===
                    "Menunggu Konfirmasi" ? null : detailTransaction?.status ===
                    "Diproses" ? null : detailTransaction?.status ===
                    "Pemasangan Selesai" ? null : (
                    <Button
                      disabled={loading}
                      variant="big"
                      onClick={() => setIsConfirmModalActive(true)}
                    >
                      {loading
                        ? "Memproses.."
                        : defineStatus(detailTransaction?.status)}
                    </Button>
                  )}
                </div>
              </div>
              <div className="transaction-detail__content">
                <div className="content">
                  <div className="content__header-note">
                    <div className="content__header-note-detail">
                      <h4 className="font--bold">Catatan Pemasangan</h4>
                      {detailTransaction?.note ? (
                        <p>{detailTransaction.note}</p>
                      ) : (
                        <p> - </p>
                      )}
                    </div>
                    <div className="content__header-note-detail">
                      <h4 className="font--bold">Jadwal Pemasangan</h4>
                      <div>
                        <h6>Sabtu, 12 April 2021</h6>
                      </div>
                    </div>
                  </div>
                  <div className="content__header-report">
                    <div className="content__header-report-detail">
                      <h4 className="font--bold">Laporan Penerimaan</h4>
                      <div>
                        <div>
                          <h6>Diterima</h6>
                          <h6>Sabtu, 12 April 2021</h6>
                        </div>
                        <div>
                          <h6>Foto Penerimaan Produk</h6>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h6>Catatan Penerimaan</h6>
                          <h6>-</h6>
                        </div>
                        <div>
                          <img src={Wheel} alt="wheel" />
                          <img src={Wheel} alt="wheel" />
                          <img src={Wheel} alt="wheel" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <Modal
          type="confirm"
          title={`Apakah Anda Yakin Ingin ${defineStatus(
            detailTransaction?.status
          )}? Pesanan ${detailTransaction?.order_number}?`}
          buttonText={
            loading ? "Memproses.." : defineStatus(detailTransaction?.status)
          }
          isModalActive={isConfirmModalActive}
          onModalClosed={() => setIsConfirmModalActive(false)}
          onModalConfirmed={handleTransaction}
          isLoading={false}
        />
      </>
    );
  };
  return (
    <Template
      noNavbar
      isSideNav
      scrollEvent
      istitle="Detail Transaksi"
      component={component}
    />
  );
};

export default TransactionDetail;
